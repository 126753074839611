@import "_generated-font-mixins.scss";
.bargaining__mob {
  margin: 16px 0 15px;

  & button {
    cursor: pointer;

    display: block;

    box-sizing: border-box;
    height: 36px;

    font-size: 15px;
    line-height: 34px;
    text-align: center;

    border-radius: 4px;
  }

  & div {
    z-index: 1015;
  }
}

.recomanded {
  &__title {
    padding: 10px 0 0 10px;

    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #333;
  }
}

.user {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  height: 50px;
  padding-left: 31px;

  line-height: 50px;
  color: #2d82eb;
  text-decoration: none;

  &::before {
    @include icon-profile;

    position: absolute;
    top: 0;
    left: -3px;

    font-size: 16px;
    color: rgb(153 153 153);
  }

  &__reviews {
    padding-left: 1px;
  }

  & i {
    padding: 0 0 5px 9px;
    font-size: 17px;
    color: rgb(252 175 68);
  }

  & span {
    margin-left: 5px;
  }
}

.bottom__bar {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 0;

  display: flex;
  gap: 10px;

  width: 100%;
  padding: 10px;

  background: #fff;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 12%);

  &__phone {
    width: 100%;

    & button {
      background: #0058c5 !important;
      border-radius: 8px;
    }

    & i {
      margin-right: 8px;
      font-size: 24px;
      color: #fff;
    }
  }

  &__message {
    cursor: pointer;

    box-sizing: border-box;
    width: 83px;
    height: 40px;

    font-size: 15px;
    line-height: 38px;
    text-align: center;

    border: 1px solid #006bb8;
    border-radius: 8px;

    & i {
      font-size: 24px;
      color: #006bb8;
    }
  }

  &__onlyChat {
    width: 100%;

    & button {
      background: #0058c5 !important;
      border-radius: 8px;
    }

    & i {
      margin-right: 8px;
      font-size: 24px;
      color: #fff;
    }
  }
}

.negotiable {
  width: 100%;
  margin-bottom: 5px;

  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  color: #333;
  text-align: left;
}

.price {
  display: flex;

  width: 100%;

  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  color: #333;
  text-align: left;

  &__main {
    margin-right: 3px;

    &__from {
      margin-right: 5px;
      font-weight: 400;
    }

    &__service {
      font-weight: 400;
    }
  }

  &__converted {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    & li {
      font-size: 12px;
      font-weight: 500;
      line-height: 25px;
      color: #999;
      white-space: nowrap;

      &::before {
        content: '/';
        margin: 0 4px;
        font-weight: 500;
      }
    }
  }

  &__item:first-of-type {
    color: #999;
  }

  &__column {
    flex-direction: column;
  }
}

.ad__container {
  padding: 0 10px;

  &__promote {
    margin: 15px 0;
  }

  &:last-child {
    @media screen and (width <= 640px) {
      padding-bottom: 50px;
    }
  }
}

.settings {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 45px;
  height: 50px;

  background-color: #ececef;

  &::before {
    @include icon-settings;

    font-size: 25px;
    color: #555;
    transition: color 0.3s;
  }
}

.settingsopen {
  &::before {
    color: #2d82eb;
  }
}

.favorite__button {
  position: absolute;
  top: 0;
  right: 0;
}
